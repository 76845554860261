import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

// Components
import Footer from "../components/organisms/footer";
import SEO from "../components/organisms/seo";
import Layout from "../components/layout";
import ContentOverflow from "../components/organisms/content-overflow";
import BlogArticle from "../components/blog/BlogArticle";
import Header1 from "../components/atoms/header-1";

// Styles
const TitleWrapper = styled.section`
  border-bottom: 2px solid #DADADA;
  margin-bottom: 30px;
`

const BlogWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Spacer2 = styled.section`
  height: 250px;
  @media(max-width: 1100px) {
    height: 0px;
  }
`


const TheStoryOfTheBiblePage = () => (
  <Layout>
    <SEO title="Bev's Blog | Beverly Hubbard" keywords={[`beverly hubbard`, `beverly`, `hubbard`, `christian author`, `christian`, `author`, `the story of the bible`, `book writer`, `christian book author`, `blog`, `articles`, `guides`]} />
    
    <Spacer2/>

    <ContentOverflow topOverflow='100px' bottomOverflow='0'>
      <TitleWrapper>
        <Header1 spacing='30px'>
          Bev's Blog
        </Header1>
      </TitleWrapper>
      
      <BlogWrapper>
        <StaticQuery
          query={graphql`
            query {
              allMarkdownRemark(    
                sort: {
                  fields: [frontmatter___date]
                  order: DESC
                }
              ) {
                edges {
                  node {
                    fields {
                      path
                    }
                    excerpt(pruneLength: 175)
                    fileAbsolutePath
                    frontmatter {
                      title
                      date
                      image
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <>
              {data.allMarkdownRemark.edges.length > 0 &&
                data.allMarkdownRemark.edges.map((edge, i) => (
                  edge.node &&
                    <BlogArticle
                      key={i}
                      img={edge.node.frontmatter.image}
                      imgAlt={edge.node.frontmatter.title}
                      link={edge.node.fields.path}
                      date={edge.node.frontmatter.date}
                      title={edge.node.frontmatter.title}
                      paragraph={edge.node.excerpt}
                    />
                ))
              }
            </>
          )}
        />
      </BlogWrapper>
    </ContentOverflow>
    <Footer/>
  </Layout>
)

export default TheStoryOfTheBiblePage