import React from 'react'
import {PropTypes} from 'prop-types'
import { Link } from 'gatsby'
/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import TimeIndicator from '../molecules/TimeIndicator';
import Header4 from '../atoms/header-4';
import Paragraph1 from '../atoms/paragraph-1';
import Link1 from '../atoms/link-1';

const Container = styled.article`
  width: calc(50% - 10px);
  box-shadow: 0px 4px 4px rgba(0,0,0,0.6);
  margin-bottom: 20px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
    h4 {
      color: #745077;
    }
  }
  h4 {
    transition: 0.2s;
  }
  &:nth-of-type(2n) {
    margin-left: 20px;
  }
  @media(max-width: 768px) {
    width: 100%;
    margin-left: 0;
    &:nth-of-type(2n) {
      margin-left: 0px;
    }
  }
`

const Wrapper = styled(Link1)`
`

const Banner = styled.section`
  width: 100%;
  height: 175px;
  overflow: hidden;
`

const ContentWrapper = styled.section`
  padding: 10px 20px 10px 20px;
`

const DateWrapper = styled.section`
  width: 100%;
  height: 20px;
  text-transform: capitalize;
  text-align: right;
`

const TitleWrapper = styled.section`
  width: 100%;  
  height: 30px;
`

const ParagraphWrapper = styled.section`
  width: 100%;
`

const BlogArticle = ({ img, imgAlt, link, date, title, paragraph }) => (
  <Container>
    <Wrapper href={link} internalManual fontSize='100%'>
      <Banner
        css={css`
          background: url(${img}) no-repeat center/cover;
        `}
      />

      <ContentWrapper>
        <DateWrapper>
          <TimeIndicator sm time={date}/>
        </DateWrapper>

        <TitleWrapper>
          <Header4 fontSize={'1.375rem'}>
            {title}
          </Header4>
        </TitleWrapper>

        <ParagraphWrapper>
          <Paragraph1>
            {paragraph}
          </Paragraph1>
        </ParagraphWrapper>
      </ContentWrapper>

    </Wrapper>
  </Container>
)

export default BlogArticle